import { eventChannel, END } from 'redux-saga';
import { put, takeEvery, call, take, fork, cancel } from 'redux-saga/effects';
import { PREVIEW_PLAY, PREVIEW_PAUSE, PREVIEW_STOP, previewUpdateProgress } from '../actions/preview-player';

let currentAudio: HTMLAudioElement | null = null;
let progressTask: any = null;

function createProgressChannel(audio: HTMLAudioElement) {
    return eventChannel(emit => {
        const onTimeUpdate = () => {
            emit({
                progress: audio.currentTime,
                duration: audio.duration
            });
        };

        const onEnded = () => {
            emit(END);
        };

        audio.addEventListener('timeupdate', onTimeUpdate);
        audio.addEventListener('ended', onEnded);

        return () => {
            audio.removeEventListener('timeupdate', onTimeUpdate);
            audio.removeEventListener('ended', onEnded);
        };
    });
}

function* watchProgress(audio: HTMLAudioElement) {
    const channel = yield call(createProgressChannel, audio);
    try {
        while (true) {
            const progress = yield take(channel);
            yield put(previewUpdateProgress(progress.progress, progress.duration));
        }
    } finally {
        channel.close();
    }
}

function* handlePreviewPlay(action: any) {
    if (currentAudio) {
        currentAudio.pause();
        if (progressTask) {
            yield cancel(progressTask);
        }
        currentAudio = null;
    }

    currentAudio = new Audio(action.payload);
    progressTask = yield fork(watchProgress, currentAudio);
    currentAudio.play();
}

function* handlePreviewPause() {
    if (currentAudio) {
        currentAudio.pause();
    }
}

function* handlePreviewStop() {
    if (currentAudio) {
        currentAudio.pause();
        if (progressTask) {
            yield cancel(progressTask);
            progressTask = null;
        }
        currentAudio = null;
    }
}

export default function* previewPlayerSaga() {
    yield takeEvery(PREVIEW_PLAY, handlePreviewPlay);
    yield takeEvery(PREVIEW_PAUSE, handlePreviewPause);
    yield takeEvery(PREVIEW_STOP, handlePreviewStop);
} 