﻿import { push } from '@festify/redux-little-router';
import { call, put, select, takeLatest } from 'redux-saga/effects';

import { showToast } from '../actions';
import { NOTIFY_AUTH_STATUS_KNOWN } from '../actions/auth';
import {
    createNewParty,
    createPartyFail,
    joinPartyFail,
    joinPartyStart,
    resolveShortId,
    CREATE_PARTY_START,
    JOIN_PARTY_START,
} from '../actions/party-data';
import { LIVEZILLA_LOAD_START } from '../actions/livezilla'; // Asegúrate de definir esta acción.
import { Views } from '../routing';
import { PartySettings, State } from '../state';

function* createParty() {
    const { player, user }: State = yield select();
    const spotifyUser = user.credentials.spotify.user;

    if (!spotifyUser) {
        const e = new Error('Missing Spotify user');
        yield put(createPartyFail(e));
        return;
    }

    const userDisplayName = spotifyUser.display_name || spotifyUser.id;
    let partyId: string;
    try {
        partyId = yield call(
            createNewParty,
            userDisplayName,
            player.instanceId,
            spotifyUser.country,
            PartySettings.defaultSettings(),
        );
    } catch (err) {
        yield put(createPartyFail(err));
        return;
    }

    yield put(push(`/party/${partyId}`));
}

function* joinParty(ac: ReturnType<typeof joinPartyStart>) {
    const { homeView }: State = yield select();

    if (!homeView.partyIdValid) {
        const e = new Error('Party ID is invalid!');
        yield put(joinPartyFail(e));
        return;
    }

    const longId = yield call(resolveShortId, homeView.partyId);

    if (!longId) {
        const e = new Error('No hay parches con este código!');
        yield put(joinPartyFail(e));
        return;
    }

    yield put(push(`/party/${longId}`));
}

function* warnNonPremium() {
    const { router, user }: State = yield select();

    if (
        (router.result || { view: Views.Home }).view !== Views.Home ||
        !user.credentials.spotify.user ||
        user.credentials.spotify.user.product === 'premium'
    ) {
        return;
    }

    yield put(
        showToast(
            "Para crear parches, debes tener una cuenta 'Spotify Premium'. Vuelva a iniciar sesión con una cuenta premium si desea organizar un buen Parche.",
            10000,
        ),
    );
}

function* loadLivezilla() {
    try {
        // Añade el div requerido
        let div = document.getElementById('lvztr_0b8');
        if (!div) {
            div = document.createElement('div');
            div.id = 'lvztr_0b8';
            div.style.display = 'none';
            document.body.appendChild(div);
        }

        // Añade el script requerido
        const existingScript = document.getElementById('lz_r_scr_4543b6dbb458e8af5648d602a11ba977');
        if (existingScript) {
            existingScript.remove();
        }

        const script = document.createElement('script');
        script.id = 'lz_r_scr_4543b6dbb458e8af5648d602a11ba977';
        script.type = 'text/javascript';
        script.defer = true;
        script.text = `lz_ovlel = [{type:"wm",icon:"commenting"},{type:"custom1",icon:"camera",title:"cHBwcA__",color:"red",margin:[0,0,0,0],href:"aHR0cHM6Ly9mb29kZ3Vhbi5jby9tZXNhcy90aGVwYXJjaGU2OQ__",open:"internal"}];lz_ovlel_rat = 1.4;lz_code_id="4543b6dbb458e8af5648d602a11ba977";var script = document.createElement("script");script.async=true;script.type="text/javascript";var src = "//chat.microdelicos.co/server.php?rqst=track&output=jcrpt&hfk=MQ__&ovlv=djI_&ovlc=MQ__&esc=IzJlOGFlNQ__&epc=IzMwOTFmMg__&ovlts=MA__&hfk=MQ__&ovlapo=MQ__&nse="+Math.random();script.src=src;document.getElementById('lvztr_0b8').appendChild(script);`;

        div.appendChild(script);

        console.log("Livezilla cargado exitosamente");
    } catch (err) {
        console.error("Hubo un error cargando Livezilla:", err);
    }
}

export default function*() {
    yield takeLatest(NOTIFY_AUTH_STATUS_KNOWN, warnNonPremium);
    yield takeLatest(CREATE_PARTY_START, createParty);
    yield takeLatest(JOIN_PARTY_START, joinParty);
    yield takeLatest(LIVEZILLA_LOAD_START, loadLivezilla); // Manejar la acción de cargar Livezilla.
}


