﻿import '@polymer/iron-icon/iron-icon';
import '@polymer/paper-icon-button/paper-icon-button';
import { connect } from 'fit-html';
import { html } from 'lit-html';
import Swal from 'sweetalert2'
import QRCode from 'qrcode-generator';


import { logout, triggerOAuthLogin } from '../actions/auth';
import { handleLinkClick } from '../actions/nav';
import { toggleUserMenu } from '../actions/view-queue-drawer';
import { PartyViews } from '../routing';
import { isPartyOwnerSelector } from '../selectors/party';
import {
    queueRouteSelector,
    settingsRouteSelector,
    shareRouteSelector,
   // tvRouteSelector,
} from '../selectors/routes';
import { currentUsernameSelector } from '../selectors/users';
import { State } from '../state';

import { loadLiveZillaScript } from '../state';

//loadLiveZillaScript("MKD1");

import theparcheplayerlogo from '../util/festify-logo';
import sharedStyles from '../util/shared-styles';

interface QueueDrawerProps {
    isOwner: boolean;
    queueRoute: string;
    settingsRoute: string;
    shareRoute: string;
    subView: PartyViews;
   // tvRoute: string;
    userMenuOpen: boolean;
    username: string | null;
    namespotyuser: string; // <-- Add this line
    shortidurl: boolean|string;
    //menuvalue: boolean|string;
    qrImage: string;
}

interface QueueDrawerDispatch {
    handleClick: (ev: Event) => void;
    enterAdmin: () => void;
    logout: () => void;
    toggleUserMenu: () => void;
}
let hasLoadedLiveZilla = false; // Variable de control fuera de la función


//console.log("5 queue", props.namespotyuser)
const isActive = (isActive: boolean) => (isActive ? 'active' : '');

/* tslint:disable:max-line-length */
const QueueDrawer = (props: QueueDrawerProps & QueueDrawerDispatch) => {
    if (props.namespotyuser && !hasLoadedLiveZilla) {
        
        loadLiveZillaScript(props.namespotyuser);
        hasLoadedLiveZilla = true; // Marcar como ejecutado
      }

 // console.log(`sdsdThe current username is ${props.username}`);

//////console.log(`lectura de variable com props: ${props.shortidurl}  ${props.namespotyuser}`);


   

   

    return html`
    ${sharedStyles}
    <style>
        :host {
            color: rgba(255, 255, 255, 0.54);
            display: block;
            min-height: 100vh;
        }

        a,
        a:visited,
        .dropdown-content {
            color: rgba(0, 0, 0, 0.8);
            text-decoration: none;
            font-weight: bolder;
        }

        .hidable {
            transform-origin: 50% 0;
            transition: transform 0.3s ease, opacity 0.3s ease;
        }

        .hidable.hidden {
            opacity: 0;
            pointer-events: none;
            transform: scale3d(1, 1.1, 1);
        }

        /*
         * Header
         */
        header {
            background: linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)),
                url(https://res.cloudinary.com/dze3qklw3/image/upload/v1707956068/zpdlhpq72tnwpx07mvmg.jpg) no-repeat center;.

                
            background-size: cover;
            display: flex;
            flex-flow: column nowrap;
            height: 176px;
            position: relative;
        }

        header svg {
            margin: 16px 0 0 16px;
            height: 64px;
            width: 64px;
        }

        .user-menu {
            align-items: center;
            display: flex;
            font-weight: lighter;
            margin-top: auto;
            cursor: pointer;
        }

        .user-menu span {
            margin-left: 16px;
        }

        .user-menu paper-icon-button {
            margin-left: auto;
            transition: transform 0.3s ease;
        }

        .user-menu paper-icon-button.open {
            transform: rotate(-180deg);
        }

        /*
         * Menu
         */
        .menu {
            display: block;
            margin-top: 24px;
            position: absolute;
            width: 100%;
        }

        .menu a {
            display: block;
            font-size: 16px;
            line-height: 24px;
            margin: 0 0 24px 24px;
            cursor: pointer;
        }

        .menu iron-icon {
            color: rgba(0, 0, 0, .54);
            margin: 0 16px 2px 0;
        }

        .menu a.active,
        .menu a.active iron-icon {
            color: var(--primary-color);
        }
    </style>

    <header>
      
     <div class="user-menu hidable ${props.username ? '' : 'hidden'}" @click=${props.toggleUserMenu}>
            <span>${props.username}</span>
            <paper-icon-button icon="theparcheplayer:expand-more"
                               class="${props.userMenuOpen ? 'open' : ''}"
                               title="Abrir menu">
            </paper-icon-button>
        </div>

    </header>

    <div class="menu hidable ${props.userMenuOpen ? 'hidden' : ''}" role="menu">
        <a
            href="${props.queueRoute}"
            class="${isActive(
                props.subView === PartyViews.Queue || props.subView === PartyViews.Search,
            )}"
            @click=${props.handleClick}
        >
            <iron-icon icon="theparcheicon21:icon-music"></iron-icon>
            lista
        </a>
                </a>




        <a href="https://mkd.musiverso.com/#${props.namespotyuser}" target="_blank">
            <iron-icon icon="theparcheicon21:icon-fire"></iron-icon>
            Estadisticas
        </a>

                <a href="https://trivia.musiverso.com/" target="_blank">
            <iron-icon icon="theparcheicon21:icon-cassette"></iron-icon>
            Trivia
        </a>

        <a href="#" @click=${ev => {
            ev.preventDefault();
            Swal.fire({
                title: 'SCAN AND JOIN',
               // text: 'Aquí puedes poner el mensaje que desees.',
                //icon: 'info',
                confirmButtonText: 'OK',
                showCloseButton: true,
                showConfirmButton: false,
                html: `${props.qrImage}` // Aquí añades la imagen
            });
        }}>
            <iron-icon icon="theparcheicon21:icon-qr_code_2"></iron-icon>
            QR Party
        </a>









        <a href="/" @click=${props.handleClick}>
            <iron-icon icon="theparcheicon21:cerrrr"></iron-icon>
            Salir
        </a>
    </div>

       <div class="menu hidable ${props.userMenuOpen ? '' : 'hidden'}" role="menu">
        





 




        <a
            href="#"
            @click=${ev => {
                ev.preventDefault();
                props.logout();
            }}
        >
            <iron-icon icon="theparcheplayer:exit-to-app"></iron-icon>
            Logout
        </a>

    </div>  
 

`;
};

/* tslint:enable */





   const mapStateToProps = (state: State): QueueDrawerProps => {
    const username = currentUsernameSelector();
    const namespotyuser = state.party.currentParty
      ? state.party.currentParty.name2
      : '';
  
    const shortidurl = state.party.currentParty
      ? state.party.currentParty.short_id22
      : '';
  
    // if (namespotyuser) {
    //   loadLiveZillaScript(namespotyuser);
    // }
    const partyidd =  state.party.currentParty ? state.party.currentParty.short_id : '';
    const url = "https://player.musiverso.com/" + partyidd;
    const qr = QRCode(0, 'L'); // Usa la función en lugar de la propiedad
    qr.addData(url);
    qr.make();
    
    //https://www.npmjs.com/package/qrcode-generator
    const qrImage = qr.createImgTag(10, 0);

  
    return {
        isOwner: isPartyOwnerSelector(state),
        queueRoute: queueRouteSelector(state)!,
        settingsRoute: settingsRouteSelector(state)!,
        shareRoute: shareRouteSelector(state)!,
        subView: state.router.result!.subView,
        //tvRoute: tvRouteSelector(state)!,
        userMenuOpen: state.partyView.userMenuOpen,
        username: username,
        namespotyuser: namespotyuser,
        shortidurl: shortidurl,
        qrImage: qr.createImgTag(10, 0),
    };
  };
  



const mapDispatchToProps: QueueDrawerDispatch = {
    enterAdmin: () => triggerOAuthLogin('spotify'),
    handleClick: handleLinkClick,
    logout,
    toggleUserMenu,
};

customElements.define('queue-drawer', connect(mapStateToProps, mapDispatchToProps)(QueueDrawer));