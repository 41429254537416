import 'dom-flip';
import { connect } from 'fit-html';
import { html } from 'lit-html';




import { handleLinkClick } from '../actions/nav';
import { isPartyOwnerSelector } from '../selectors/party';
import { settingsRouteSelector } from '../selectors/routes';
import { queueTracksSelector } from '../selectors/track';
import { State, Track } from '../state';
import { searchState   } from '../state';
import sharedStyles from '../util/shared-styles';

import './party-track';

export interface PartyQueueProps {
    hasTracksLoaded: boolean;
    isOwner: boolean;
    settingsRoute: string;
    tracks: Track[];
     isSearchUnlocked: boolean; // Add this line
}

interface PartyQueueDispatch {
    handleClick: (ev: Event) => void;
}

export const queueStyles = html`
    <style>
        :host {
            background-color: var(--track-bg);
            position: relative;
        }

        party-track:nth-child(even),
        party-track-search:nth-child(even) {
            background-color: var(--track-bg-even);
        }
    </style>
`;

/* tslint:disable:max-line-length */
const List = (props: PartyQueueProps & PartyQueueDispatch) => {
    //////console.log('Estado de isSearchUnlocked:', props.isSearchUnlocked);
    if (!props.hasTracksLoaded) {
        return html`
            <div class="spinner">
                <paper-spinner-lite active alt="Loading tracks..."></paper-spinner-lite>
            </div>
        `;
    }

    if (!props.tracks.length ) {
        const inner = props.isOwner
            ? html`
                <a href="${props.settingsRoute}"
                   @click=${props.handleClick}> 
                    En configuraciones</a>
                puedes agregar una lista de reproducción
            `
            : 'Ingresa un código o habla con el anfitrión';

        return html`
            <h2>😢 Sin música por ahora</h2>
            <h3>${inner}</h3>
        `;
    } else if (!props.tracks.length) {
        return html`
            <h2>🤷 No hay canciones en la lista!</h2>
            <h3>¿Que esperas para añadirlas?</h3>
        `;
    }

    const list = props.tracks.map(
        (track, i) => html`
            <party-track
                ?playing=${i === 0}
                data-flip-id="${track.reference.provider}-${track.reference.id}"
                .trackid="${track.reference.provider}-${track.reference.id}"
            >
            </party-track>
        `,
    );

    return window.ShadyCSS && !window.ShadyCSS.nativeShadow
        ? html`
              <div>${list}</div>
          `
        : html`
              <dom-flip>${list}</dom-flip>
          `;
};


const PartyQueue = (props: PartyQueueProps & PartyQueueDispatch) => html`
    ${sharedStyles} ${queueStyles}
    <style>
        .spinner {
            display: flex;
            justify-content: center;
            margin: 32px;
        }

        party-track[playing] + party-track {
            padding-top: 13px;
        }

        h2,
        h3 {
            margin-left: 16px;
            margin-right: 16px;
            text-align: center;
        }
    </style>

    ${List(props)}
`;
/* tslint:enable */

const mapStateToProps = (state: State): PartyQueueProps => ({
    hasTracksLoaded: state.party.hasTracksLoaded,
    isOwner: isPartyOwnerSelector(state),
    settingsRoute: settingsRouteSelector(state)!,
    tracks: queueTracksSelector(state),
     isSearchUnlocked: searchState.isSearchUnlocked, // Add this line
});

const mapDispatchToProps: PartyQueueDispatch = {
    handleClick: handleLinkClick,
};

customElements.define('party-queue', connect(mapStateToProps, mapDispatchToProps)(PartyQueue));
