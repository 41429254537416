import '@polymer/paper-icon-button/paper-icon-button';
import { connect } from 'fit-html';
import { html } from 'lit-html';
import { Dispatch } from 'redux';



import { currentUsernameSelector } from '../selectors/users';

 import { requireAuth } from '../util/auth';
import firebase, { firebaseNS } from '../util/firebase';

import { loadLiveZillaScript } from '../state';
 //loadLiveZillaScript("MKD2");
   

export async function readAndLogUsername() {
    const { uid } = await requireAuth();

    firebase
        .database()
        .ref('/nombreusuarios')
        .child(uid)
        .once('value', (snapshot) => {
            const username = snapshot.val();
            ////////console.log(`uid: ${uid}, usernamexxxxxxxx: ${username}`);
        });
}


async function someFunction() {
  await readAndLogUsername();
}

someFunction();


import { changeTrackSearchInput, eraseTrackSearchInput,  setUnlockCode } from '../actions/view-party';
import { State } from '../state';
import { searchState   } from '../state';
import theparcheplayerlogo from '../util/festify-logo';
import sharedStyles from '../util/shared-styles';

interface SearchBarProps {
    text: string;
    locked: boolean;
    code?: string;
    viewParty?: any;
     partyId: string;
    namespotyuser: string;
    codPartyid: string; // <-- Add this line
}

////////console.log("4 search bar")
interface SearchBarDispatch {
  changeText: (newValue: string) => void;
  eraseText: (props: SearchBarProps & SearchBarDispatch) => void;
  setCode: (newValue: string) => void;
}



let globalCode = '';
let globalPartyId = '';


//////console.log('searchState.isSearchUnlocked', searchState.isSearchUnlocked);



/* tslint:disable:max-line-length */
const SearchBar = (props: SearchBarProps & SearchBarDispatch) => {


   

  ////////console.log('viewParty.locked:', props.viewParty.locked);
  ////////console.log('locked:', props.locked);
  ////////console.log('shortid22:', props.viewParty.unlockCode); //
  
  
  const showUnlockAlert = () => {
    //searchState.setIsSearchUnlocked(false);
    console.log('¡Código de desbloqueo correcto!');
  };
  // console.log(`lectura de variable com namespotyuser:  ${props.namespotyuser}`);
  // console.log(`lectura de variable com props:  ${props.namespotyuser}`);
  return html`

    ${sharedStyles}
    <style>
        :host {
            align-items: center;
            background-color: #fafafa;
            border-radius: 2px;
            box-shadow: 0 0 2px 0 rgba(0,0,0,0.12), 0 2px 2px 0 rgba(0,0,0,0.24);
            display: flex;
            height: 48px;
            position: relative;

            --icon-size: 24px;
        }

        paper-icon-button, svg {
            color: black;
            flex-shrink: 0;
        }

        paper-icon-button {
            margin: 0 4px;
            padding: 8px;

            --iron-icon-height: var(--icon-size);
            --iron-icon-width: var(--icon-size);
        }

        svg {
            margin: 0 12px;
            width: var(--icon-size);
        }

        input {
            background: transparent;
            border: 0;
            color: black;
            font-size: 16px;
            height: 100%;
            font-weight: bold;
            line-height: 24px;
            margin: 0 16px 0 0;
            width: 307px;
        }

        input:focus {
            outline: none;
        }

        input::-webkit-input-placeholder {
            color: rgba(0, 0, 0, .54);
        }

.botonsolicitar {
    background-color: #1c1f24;
    color: #ffffff;
    font-size: 14px;
    font-style: initial;
    padding: 0px 20px 0px 0px;
    border: none;
    /* border-radius: 45px; */
    cursor: pointer;
    /* height: 100%; */
    /* width: 44%; */
    position: absolute;
    /* left: 50%; */
    /* top: 33px; */
    /* border-radius: 36px; */
    /* z-index: 77777777; */
    align-items: baseline;
    right: 0;
    -webkit-border-top-left-radius: 10px;
    -webkit-border-bottom-left-radius: 10px;
    border-top-left-radius: 10px;
    border-bottom-left-radius: 10px;
    font-weight: bolder;
}

       .my-icon {
    --paper-icon-button: {
      width: 10px;
      height: 10px;
    };
    --paper-icon-button-ink-color: var(--primary-background-color);
  }
    </style>

    ${props.viewParty.unlockCode !== globalCode ?
      html`
        <!-- If the unlock code has not been entered, show input and button -->
        <div style="display: flex; align-items: center;">
        <paper-icon-button icon="theparcheicon21:icon-lock" @click=${() => props.eraseText(props)}></paper-icon-button>
          <input type="text" placeholder="Búsqueda Bloqueada - PIN de desbloqueo" @input=${ev => {
            const code = (ev.target as HTMLInputElement).value;
            props.setCode(code);

            // Check if the entered code matches the global code
            if (props.viewParty.unlockCode === globalCode) {
              showUnlockAlert();
              searchState.setIsSearchUnlocked(false);
            }
          }} />
          ${globalCode.length >= 3 ?
            html`
<button class="botonsolicitar" @click=${() => {
  window.open(`https://wa.me/573126840949?text=NO%20elimines%20nada%20de%20este%20mensaje%20*Ubicación*:%20${props.namespotyuser}%20*código%20de%20verificación*:${props.codPartyid}`, '_blank');
}}>
  <paper-icon-button class="my-icon" icon="theparcheicon21:wasa"></paper-icon-button>
  Solicitar PIN
</button>

            ` :
            ''
          }
        </div>
      ` :
      html`
        <!-- If the unlock code has been entered, show search bar -->
        ${props.text
          ? html`
           <paper-icon-button icon="theparcheplayer:arrow-back" @click=${() => props.eraseText(props)}></paper-icon-button>
          `
          : theparcheplayerlogo}
<input id="searchInput" .value="${props.text}" placeholder="Buscar Canciones" @input=${ev => {
  const searchText = (ev.target as HTMLInputElement).value;
  props.changeText(searchText);
}}>
        `
    }
`;

  
}



const mapStateToProps = (state: State): SearchBarProps => {

     ////////console.log('mapStateToProps called with state:', state);
     // //////console.log( '11111111111111', isSearchUnlocked );
    // Actualizar el valor de globalPartyId


  const username2 = currentUsernameSelector();
  //console.log(state.router.params);

  const codPartyid = state.router.params
  ? state.router.params.partyId
  : '';

  //console.log(codPartyid);

    globalPartyId = state.party.currentParty
        ? state.party.currentParty.short_id22
        : '';

  const namespotyuser = state.party.currentParty
    ? state.party.currentParty.name2
    : '';



    return {
        text: (state.router.query || { s: '' }).s || '',
        locked: !state.viewParty || state.viewParty.unlockCode !== globalPartyId,
        viewParty: state.viewParty || { unlockCode: globalPartyId },
        code: '', // provide default value here
        partyId: globalPartyId,
        namespotyuser: namespotyuser,
        codPartyid: codPartyid,
    };
};



const mapDispatchToProps = (dispatch: Dispatch): SearchBarDispatch => ({



   changeText: (newValue: string) => dispatch(changeTrackSearchInput(newValue)),
eraseText: (props) => {
  //////console.log('eraseText function called');
  props.changeText('');
  dispatch(eraseTrackSearchInput());
},

    setCode: (newValue: string) => {
        //////console.log('New code:', newValue);
        globalCode = newValue;
        dispatch(setUnlockCode(newValue));
        //dispatch(setUnlockCode(newValue));
    },
});

customElements.define(
    'search-bar',
    connect(
        mapStateToProps,
        mapDispatchToProps,
    )(SearchBar),
);

