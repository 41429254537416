import { PREVIEW_PLAY, PREVIEW_PAUSE, PREVIEW_STOP, PREVIEW_UPDATE_PROGRESS } from '../actions/preview-player';

export interface PreviewPlayerState {
    isPlaying: boolean;
    currentPreviewUrl: string | null;
    progress: number;
    duration: number;
}

const initialState: PreviewPlayerState = {
    isPlaying: false,
    currentPreviewUrl: null,
    progress: 0,
    duration: 0
};

export default function previewPlayerReducer(state = initialState, action: any): PreviewPlayerState {
    switch (action.type) {
        case PREVIEW_PLAY:
            return {
                ...state,
                isPlaying: true,
                currentPreviewUrl: action.payload,
                progress: 0,
                duration: 0
            };
        case PREVIEW_PAUSE:
            return {
                ...state,
                isPlaying: false,
            };
        case PREVIEW_STOP:
            return {
                ...state,
                isPlaying: false,
                currentPreviewUrl: null,
                progress: 0,
                duration: 0
            };
        case PREVIEW_UPDATE_PROGRESS:
            return {
                ...state,
                progress: action.payload.progress,
                duration: action.payload.duration
            };
        default:
            return state;
    }
} 