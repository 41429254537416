import '@polymer/paper-button/paper-button';
import '@polymer/paper-checkbox/paper-checkbox';
import '@polymer/paper-icon-button/paper-icon-button';
import '@polymer/paper-input/paper-input';
import '@polymer/paper-spinner/paper-spinner-lite';
import { connect } from 'fit-html';
import { html } from 'lit-html';

import { triggerOAuthLogin } from '../actions/auth';
import {
    changePartyName,
    changePartyName2,
    changePartyName22,
    changePartySetting,
    changeSearchInput,
    flushQueueStart,
    insertPlaylistStart,
} from '../actions/view-party-settings';
import { filteredPlaylistsSelector } from '../selectors/playlists';
import { hasConnectedSpotifyAccountSelector } from '../selectors/users';
import { PartySettings, Playlist, State } from '../state';
import sharedStyles from '../util/shared-styles';

interface PartySettingsProps {
    isAuthorizing: boolean;
    isPlaylistLoadInProgress: boolean;
    isSpotifyConnected: boolean;
    partyName: string;
     partyName2: string;
     partyName22: string;
    playlists: Playlist[];
    playlistSearch: string;
    queueFlushInProgress: boolean;
    settings: PartySettings;
    tracksLoadInProgress: boolean;
    tracksToLoad: number;
    tracksLoaded: number;
}
////console.log("10 settings")
interface PartySettingsDispatch {
    changePartyName: (newName: string) => void;
      changePartyName2: (newName2: string) => void;
      changePartyName22: (newName22: string) => void;
    changePartySetting: <K extends keyof PartySettings>(setting: K, val: PartySettings[K]) => void;
    changeSearchInput: (newContent: string) => void;
    flushTracks: () => void;
    insert: (playlist: Playlist, shuffle: boolean) => void;
    triggerSpotifyLogin: () => void;
}

const LoginView = (props: PartySettingsProps & PartySettingsDispatch) => html`
    <h3>Inicia sesión por favor</h3>

    ${props.isAuthorizing
        ? html`
            <paper-spinner-lite active alt="Authorizing...">
            </paper-spinner-light>
        `
        : html`
              <paper-button raised class="login spotify" @click=${props.triggerSpotifyLogin}>
                  <iron-icon icon="social:spotify"></iron-icon>
                  <span>Sign in with</span>
                  Spotify
              </paper-button>
          `}
`;

const PlaylistView = (props: PartySettingsProps & PartySettingsDispatch) => html`
    <h3>listas de reproducción</h3>

    <paper-input label="Buscar Listas"
                .value=${props.playlistSearch}
                 type="text"
                 @input=${ev => props.changeSearchInput((ev.target as HTMLInputElement).value)}>
    </paper-input>

    ${props.isPlaylistLoadInProgress
        ? html`
            <paper-spinner-lite active alt="Cargando Listas...">
            </paper-spinner-light>
        `
        : null}

    ${props.playlists.map(item => html`
        <div class="fallback-playlist">
            <h4>${item.name}</h4>

            <paper-icon-button class="shuffle-button"
                               icon="theparcheplayer:shuffle"
                               @click=${() => props.insert(item, true)}
                               title="Agregar / Aleatorio"
                               .disabled=${props.tracksLoadInProgress}>
            </paper-icon-button>
            <paper-icon-button icon="theparcheplayer:add"
                               @click=${() => props.insert(item, false)}
                               title="Agregar / Ordenado"
                               .disabled=${props.tracksLoadInProgress}>
            </paper-icon-button>
        </div>
    `)}
`;

/* tslint:disable:max-line-length */
const SettingsView = (props: PartySettingsProps & PartySettingsDispatch) => 
  html`
    ${sharedStyles}
    
`;
/* tslint:enable */

const mapStateToProps = (state: State): PartySettingsProps => ({
    isAuthorizing: state.user.credentials.spotify.authorizing,
    isPlaylistLoadInProgress: state.settingsView.playlistLoadInProgress,
    isSpotifyConnected: hasConnectedSpotifyAccountSelector(state),
    partyName: (state.party.currentParty || { name: '' }).name,
    partyName2: (state.party.currentParty || { short_id: '' }).short_id,
    partyName22: (state.party.currentParty || { short_id22: '' }).short_id22,
    playlists: filteredPlaylistsSelector(state),
    playlistSearch: state.settingsView.playlistSearchQuery,
    queueFlushInProgress: state.settingsView.queueFlushInProgress,
    settings: PartySettings.defaultSettings(
        state.party.currentParty && state.party.currentParty.settings,
    ),
    tracksLoadInProgress: state.settingsView.tracksLoadInProgress,
    tracksLoaded: state.settingsView.tracksLoaded,
    tracksToLoad: state.settingsView.tracksToLoad,
});

const mapDispatchToProps: PartySettingsDispatch = {
    changePartyName,
    changePartyName2,
     changePartyName22,
    changePartySetting,
    changeSearchInput,
    flushTracks: flushQueueStart,
    insert: insertPlaylistStart,
    triggerSpotifyLogin: () => triggerOAuthLogin('spotify'),
};

customElements.define('party-settings', connect(mapStateToProps, mapDispatchToProps)(SettingsView));
