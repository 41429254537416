import { Location } from "@festify/redux-little-router";
import { User } from "@firebase/auth-types";

import { OAuthLoginProviders } from "./actions/auth";
import { domainSelector } from "./selectors/domain";

// prueba




const generateScriptText = (lzCodeId: string) => `
  (function() {
    // Detectar si es un dispositivo móvil
    const isMobile = window.innerWidth < 768;
    
    // Crear el botón flotante que siempre estará visible
    const floatingButton = document.createElement('div');
    floatingButton.id = 'widget-floating-button';
    floatingButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>';
    floatingButton.style.cssText = 'position: fixed; bottom: 20px; right: 20px; width: 50px; height: 50px; border-radius: 50%; background: #3091f2; color: white; display: flex; justify-content: center; align-items: center; cursor: pointer; box-shadow: 0 2px 10px rgba(0,0,0,0.2); z-index: 10000;';
    
    // Crear el contenedor principal del widget (oculto inicialmente)
    const widgetContainer = document.createElement('div');
    widgetContainer.id = 'widget-container';
    
    // Aplicar estilos diferentes según el dispositivo
    if (isMobile) {
      widgetContainer.style.cssText = 'position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 9999; overflow: hidden; display: flex; flex-direction: column; background: white; transition: all 0.3s ease; transform: translateY(100%); opacity: 0;';
    } else {
      widgetContainer.style.cssText = 'position: fixed; bottom: 80px; right: 20px; width: 350px; height: 500px; z-index: 9999; border-radius: 10px; box-shadow: 0 0 10px rgba(0,0,0,0.2); overflow: hidden; display: flex; flex-direction: column; background: white; transition: all 0.3s ease; transform: translateY(calc(100% + 80px)); opacity: 0;';
    }
    
    // Crear la barra superior con botón de cierre
    const headerBar = document.createElement('div');
    headerBar.style.cssText = 'display: flex; justify-content: space-between; align-items: center; padding: 10px; background: #3091f2; color: white;';
    
    const title = document.createElement('div');
    title.textContent = 'El Parche';
    
    const closeButton = document.createElement('button');
    closeButton.innerHTML = '&times;';
    closeButton.style.cssText = 'background: none; border: none; color: white; font-size: 24px; cursor: pointer;';
    closeButton.onclick = function() {
      if (isMobile) {
        widgetContainer.style.transform = 'translateY(100%)';
      } else {
        widgetContainer.style.transform = 'translateY(calc(100% + 80px))';
      }
      widgetContainer.style.opacity = '0';
      setTimeout(() => {
        widgetContainer.style.display = 'none';
      }, 300);
      
      // Actualizar el estado y el ícono cuando se cierra desde la barra superior
      isWidgetExpanded = false;
      floatingButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>';
    };
    
    headerBar.appendChild(title);
    headerBar.appendChild(closeButton);
    
    // Crear el iframe
    const iframe = document.createElement('iframe');
    iframe.src = 'https://runico.cluvi.co'; // Usar el ID como URL del iframe
    iframe.style.cssText = 'flex: 1; width: 100%; border: none;';
    iframe.allowFullscreen = true;
    
    // Ensamblar el widget
    widgetContainer.appendChild(headerBar);
    widgetContainer.appendChild(iframe);
    
    // Añadir el widget y el botón flotante al DOM
    document.body.appendChild(widgetContainer);
    document.body.appendChild(floatingButton);
    
    // Estado del widget
    let isWidgetExpanded = false;
    
    // Función para alternar el estado del widget
    floatingButton.onclick = function() {
      if (isWidgetExpanded) {
        // Colapsar
        if (isMobile) {
          widgetContainer.style.transform = 'translateY(100%)';
        } else {
          widgetContainer.style.transform = 'translateY(calc(100% + 80px))';
        }
        widgetContainer.style.opacity = '0';
        setTimeout(() => {
          widgetContainer.style.display = 'none';
        }, 300);
      } else {
        // Expandir
        widgetContainer.style.display = 'flex';
        setTimeout(() => {
          widgetContainer.style.transform = 'translateY(0)';
          widgetContainer.style.opacity = '1';
        }, 10);
      }
      isWidgetExpanded = !isWidgetExpanded;
      
      // Cambiar el ícono del botón flotante
      if (isWidgetExpanded) {
        floatingButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><line x1="18" y1="6" x2="6" y2="18"></line><line x1="6" y1="6" x2="18" y2="18"></line></svg>';
      } else {
        floatingButton.innerHTML = '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"><path d="M21 15a2 2 0 0 1-2 2H7l-4 4V5a2 2 0 0 1 2-2h14a2 2 0 0 1 2 2z"></path></svg>';
      }
    };
    
    // Solo permitir arrastrar en dispositivos de escritorio
    if (!isMobile) {
      let isDragging = false;
      let offsetX, offsetY;
      
      headerBar.onmousedown = function(e) {
        isDragging = true;
        offsetX = e.clientX - widgetContainer.getBoundingClientRect().left;
        offsetY = e.clientY - widgetContainer.getBoundingClientRect().top;
      };
      
      document.onmousemove = function(e) {
        if (!isDragging) return;
        
        widgetContainer.style.left = (e.clientX - offsetX) + 'px';
        widgetContainer.style.top = (e.clientY - offsetY) + 'px';
        widgetContainer.style.right = 'auto';
        widgetContainer.style.bottom = 'auto';
      };
      
      document.onmouseup = function() {
        isDragging = false;
      };
    }
    
    // Manejar cambios de orientación y tamaño de pantalla
    window.addEventListener('resize', function() {
      const newIsMobile = window.innerWidth < 768;
      if (newIsMobile !== isMobile && isWidgetExpanded) {
        // Reajustar estilos si cambia el tipo de dispositivo
        if (newIsMobile) {
          widgetContainer.style.cssText = 'position: fixed; top: 0; left: 0; width: 100%; height: 100%; z-index: 9999; overflow: hidden; display: flex; flex-direction: column; background: white; transition: all 0.3s ease; transform: translateY(0); opacity: 1;';
        } else {
          widgetContainer.style.cssText = 'position: fixed; bottom: 80px; right: 20px; width: 350px; height: 500px; z-index: 9999; border-radius: 10px; box-shadow: 0 0 10px rgba(0,0,0,0.2); overflow: hidden; display: flex; flex-direction: column; background: white; transition: all 0.3s ease; transform: translateY(0); opacity: 1;';
        }
      }
    });
  })();
`;

export async function loadLiveZillaScript(username: string | null) {
    if (!username) return;

    let dataItems: DataItem[] = []; // Cambiado a tipo DataItem[]
    let userConfiguration: {
        divId: string;
        scriptId: string;
        scriptText: string;
    } | null = null;

    const myHeaders = new Headers();
    myHeaders.append(
        "Authorization",
        "Bearer dda4fb9940c8156734ba7f16e5b7723b8bb62ca2863e049f582c4de20a90df0795ed18c4e3dc5e8a357d86c0ec78769564f1b55ce4ecca80df1e0e898336df7be672c5b3b299f7d0eb1b103075f05f34fa966041c7d51b26b48edc70da59f2472aaf28bac35fe45b1fbeaa26e0e2e0fb30086fe7facc4863d3cce932df03eb9f"
    );

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow" as RequestRedirect,
    };

    try {
        const response = await fetch(
            "https://strappy.musikradar.co/api/thesites?populate=*",
            requestOptions
        );
        const data = await response.json();
        dataItems = data.data; // Actualizado a 'dataItems'

        console.log(data.data);
        // Busca la coincidencia de `username` en los datos obtenidos
        for (const item of dataItems) {
            // Cambiado de 'store' a 'item'

            const menucode = item.attributes.widgetcode; // Actualizado a 'item'

            const spotifyKeys = Object.keys(item.attributes.spotify || {}).join(
                ", "
            );

            // Mostrar las claves almacenadas en un solo console.log
            console.log("Spotify Keys:", spotifyKeys);

            if (username === spotifyKeys) {
                userConfiguration = {
                    divId: "MKD4200",
                    scriptId: "musikverso",
                    scriptText: generateScriptText(menucode), // Pasa `name` a la función
                };
                break; // Salir del bucle si se encuentra la coincidencia
            }
        }

        if (!userConfiguration) {
            console.log("Username is not valid!");
            return;
        }

        const { divId, scriptId, scriptText } = userConfiguration;

        const div =
            document.getElementById(divId) ||
            (() => {
                const newDiv = document.createElement("div");
                newDiv.id = divId;
                newDiv.style.display = "none";
                document.body.appendChild(newDiv);
                return newDiv;
            })();

        const existingScript = document.getElementById(scriptId);
        if (existingScript) existingScript.remove();

        const script = document.createElement("script");
        script.type = "text/javascript";
        script.id = scriptId;
        script.defer = true;
        script.text = scriptText;

        div.appendChild(script);
    } catch (error) {
        console.error("Error al realizar la solicitud:", error);
    }
}

//usada para el boton de preview
export const botonpreview = {
  chatlock: true,
  setchatlock: function (value) {
    this.chatlock = value;
  },
};


//usada para establcer el estado de la busqueda
export const searchState = {
  isSearchUnlocked: true,
  setIsSearchUnlocked: function (value) {
    this.isSearchUnlocked = value;
  },
};

export const enum ConnectionState {
    Unknown,
    Connected,
    Disconnected,
}

export interface Image {
    height: number;
    url: string;
    width: number;
}

export interface Metadata {
    artists: string[];
    background?: string[];
    cover: Image[];
    durationMs: number;
    isPlayable: boolean;
    isrc?: string;
    name: string;
    previewUrl: string | null; // Asegúrate de que 'previewUrl' esté aquí
    addedBy?: string; // Agregue esta línea
}

export interface Playback {
    last_change: number;
    last_position_ms: number;
    master_id: string | null;
    playing: boolean;
    target_playing: boolean | null;
}

export interface Playlist {
    name: string;
    reference: PlaylistReference;
    trackCount: number;
}

export interface PlaylistReference extends TrackReference {
    userId: string;
}

export interface Party {
    country: string;
    created_at: number;
    created_by: string;
    name: string;
    name2: string;
    spotiid: string;
    tvscreenid: string;
    playback: Playback;
    settings?: PartySettings;
    short_id: string;
    short_id22: string;
    short_idQR: string;
}

export interface PartySettings {
    /**
     * Toggles whether anonymous users can vote.
     *menu4 habilitacion de 
     * This can be disabled to prevent vote cheating.
     */
    allow_anonymous_voters: boolean;
    mostrarmenu: boolean;
    mostrarmenu2: boolean;
    mostrarmenu3: boolean;
    mostrarmenu4: boolean;
    mostrarmenu5: boolean;
    mostrarmenu6: boolean;
    mostrarmenu7: boolean;

    /**
     * Toggles whether to allow explicit songs to be added to the party
     * via the search.
     *
     * Does not affect fallback playlists (intentionally).
     */
    allow_explicit_tracks: boolean;

    /**
     * Toggles whether the search menu closes after a guest has voted for
     * one track, or if multiple tracks can be added to the queue from search.
     */
    allow_multi_track_add: boolean;

    /**
     * Sets the text displayed under the progress bar in TV mode
     */
    tv_mode_text: string;

    /**
     * Sets the maximum track length in minutes
     */
    maximum_track_length: number | null;
}

// tslint:disable-next-line:no-namespace
export namespace PartySettings {
    export function defaultSettings(overrides?: Partial<PartySettings> | null): PartySettings {
        return {
            mostrarmenu: true, //qr interativo
            mostrarmenu2: true, //preview
            mostrarmenu3: false, //estado de busqueda
            mostrarmenu4: false, //wehatsappcode
            mostrarmenu5: false, //art mode
            mostrarmenu6: false, //art mode
            mostrarmenu7: false, //boton de descarga
            allow_anonymous_voters: true,
            allow_explicit_tracks: true,
            allow_multi_track_add: false,
            tv_mode_text: `Tu eres el DJ de este Parche`,
            maximum_track_length: null,
            ...overrides,
        };
    }
}

export interface Track {
    added_at: number;
    is_fallback: boolean;
    order: number;
    reference: TrackReference;
    played_at?: number;
    vote_count: number;
    saludo: "asas"
    //preview_url: string | null; // Agrega esta línea
}

export interface TrackReference {
    id: string;
    provider: 'spotify';
}

/*
 * Redux state
 */

export interface AppShellState {
    currentToast: string | null;
}

export interface HomeViewState {
    partyCreationInProgress: boolean;
    partyCreationError: Error | null;
    partyJoinInProgress: boolean;
    partyJoinError: Error | null;
    partyId: string;
    partyIdValid: boolean;
}

export interface PartyState {
    connectionState: ConnectionState;
    currentParty: Party | null;
    hasTracksLoaded: boolean;
    partyLoadError: Error | null;
    partyLoadInProgress: boolean;
    tracks: Record<string, Track> | null;
    userVotes: Record<string, boolean> | null;
}

export interface PartyViewState {
    loginModalOpen: boolean;
    searchInProgress: boolean;
    searchError: Error | null;
    searchResult: Record<string, Track> | null;
    userMenuOpen: boolean;
}

export interface PlayerState {
    localDeviceId: string | null;
    instanceId: string;
    initializing: boolean;
    initializationError: Error | null;
    isCompatible: boolean;
    sdkReady: boolean;
    togglingPlayback: boolean;
    togglePlaybackError: Error | null;
}


export interface PlayerState2 {
    localDeviceId: string | null;
    instanceId: string;
    initializing: boolean;
    initializationError: Error | null;
    isCompatible: boolean;
    sdkReady: boolean;
    togglingPlayback: boolean;
    togglePlaybackError: Error | null;
}
export interface SettingsViewState {
    playlistLoadInProgress: boolean;
    playlistLoadError: Error | null;
    playlistSearchQuery: string;
    queueFlushInProgress: boolean;
    queueFlushError: Error | null;
    tracksLoadInProgress: boolean;
    tracksLoadError: Error | null;
    tracksToLoad: number;
    tracksLoaded: number;
}

export interface AuthProviderStatus<T> {
    authorizing: boolean;
    authorizationError: Error | null;
    statusKnown: boolean;
    user: T | null;
}

export interface UserCredentials {
    facebook: AuthProviderStatus<User>;
    firebase: AuthProviderStatus<User>;
    github: AuthProviderStatus<User>;
    google: AuthProviderStatus<User>;
    spotify: AuthProviderStatus<SpotifyApi.UserObjectPrivate>;
    twitter: AuthProviderStatus<User>;
}

export type EnabledProvidersList = {
    [k in OAuthLoginProviders]: boolean;
};

// tslint:disable-next-line:no-namespace
export namespace EnabledProvidersList {
    export function enable(overrides: OAuthLoginProviders[]): EnabledProvidersList {
        const result = {
            facebook: false,
            github: false,
            google: false,
            spotify: false,
            twitter: false,
        };
        overrides.forEach(prov => (result[prov] = true));
        return result;
    }
}

export interface UserState {
    credentials: UserCredentials;

    /**
     * The list of providers the user may sign in with, if he is required
     * to sign in with one of his previous OAuth providers. Otherwise null.
     */
    needsFollowUpSignInWithProviders: EnabledProvidersList | null;

    playlists: Playlist[];
}

export interface PreviewPlayerState {
    isPlaying: boolean;
    currentPreviewUrl: string | null;
    progress: number;
    duration: number;
}

export interface State {
    appShell: AppShellState;
    homeView: HomeViewState;
    metadata: Record<string, Metadata>;
    party: PartyState;
    partyView: PartyViewState;
    player: PlayerState;
    player2: PlayerState2;
    previewPlayer: PreviewPlayerState;
    router: Location;
    settingsView: SettingsViewState;
    user: UserState;
    viewParty: ViewPartyState;
}

export interface DataItem {
    id: number;
    attributes: {
        name: string;
        createdAt: string;
        updatedAt: string;
        publishedAt: string;
        noEscanear: boolean;
        address: {
            city: string;
            postalCode: string;
            countryCode: string;
        };
        addressLines: string[];
        coordinates: {
            latitude: number;
            longitude: number;
        };
        endpoint: string;
        insta: string;
        markett: string;
        menu: string;
        widgetcode: string; // Asegúrate de que esta propiedad esté aquí
        openStatusText: string;
        phoneNumber: string | null;
        refreshToken: string;
        stat: string;
        spotify: {
            [key: string]: {
                client_id: string;
                client_secret: string;
            };
        };
        // Image: {
        //     data: {
        //         id: number;
        //         attributes: {
        //             name: string;
        //             alternativeText: string | null;
        //             caption: string | null;
        //             width: number;
        //             height: number;
        //             formats: {
        //                 [key: string]: {
        //                     ext: string;
        //                     url: string;
        //                     hash: string;
        //                     mime: string;
        //                     name: string;
        //                     path: string | null;
        //                     size: number;
        //                     width: number;
        //                     height: number;
        //                     provider_metadata: {
        //                         public_id: string;
        //                         resource_type: string;
        //                     };
        //                 };
        //             };
        //             hash: string;
        //             ext: string;
        //             mime: string;
        //             size: number;
        //             url: string;
        //             previewUrl: string | null;
        //             provider: string;
        //             provider_metadata: {
        //                 public_id: string;
        //                 resource_type: string;
        //             };
        //             createdAt: string;
        //             updatedAt: string;
        //         };
        //     }[];
        // };
    };
}

export interface ViewPartyState {
    trackSearchInput: string;
     locked: boolean;
    unlockCode: string; // agregado
}

