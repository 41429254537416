import '@polymer/paper-spinner/paper-spinner-lite';
import { connect } from 'fit-html';
import { html } from 'lit-html';

//importo para poder tomar el dato de kkkkkkk 
import { searchState } from '../state';

import { sortedTracksFactory } from '../selectors/track';
import { State, Track } from '../state';
import sharedStyles from '../util/shared-styles';

import { queueStyles } from './party-queue';
import './party-track-search';

interface SearchProps {
    searchError: Error | null;
    searchInProgress: boolean;
    tracks: Track[];
    isSearchUnlocked: boolean;
    menuvalue: boolean|string;
}

const Progress = (props: SearchProps) => {
    if (props.searchInProgress) {
        return html`
            <div class="indicator">
                <paper-spinner-lite active alt="Loading search results..."></paper-spinner-lite>
            </div>
        `;
    } else if (props.searchError) {
        return html`
            <div class="indicator">
                <h2>⚡️ Oh, no!</h2>
                <h3>An error occurred while searching. Please try again.</h3>
            </div>
        `;
    } else {
        return null;
    }
};

const PartySearchView = (props: SearchProps) => {

//////console.log('searchState.isSearchUnlocked', searchState.isSearchUnlocked);

////////console.log(`lectura de variable com propsmenuvalue: ${props.menuvalue} `);

return html`
    ${sharedStyles} ${queueStyles}
    <style>
        party-track-search:first-of-type {
            padding-top: 16px;
            z-index: 1;
        }

.indicator {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    margin: 0 32px 0 32px;
    text-align: center;
}

        paper-spinner-lite {
            background: var(--secondary-color);
        }
    </style>

    ${!props.isSearchUnlocked ? html`
        ${Progress(props)}
        ${props.tracks.map(
            (track, i) => html`
                <party-track-search trackid="${track.reference.provider}-${track.reference.id}">
                </party-track-search>
            `,
        )}
    ` : html`
        <div class="indicator">
            <h2>🔒 búsqueda bloqueada.</h2>
            <h3>Espera a que agreguen temas o solicita un código de desbloqueo</h3>
        </div>
    `}
`;
};




const tracksSelector = (state: State) => state.partyView.searchResult;
const sortedTrackSelector = sortedTracksFactory(tracksSelector);

const mapStateToProps = (state: State): SearchProps => {



const menuvalue = state.party.currentParty && state.party.currentParty.settings ? state.party.currentParty.settings.mostrarmenu3 : 'undefined';
   //////console.log('chatvalue', state.party.currentParty && state.party.currentParty.settings ? state.party.currentParty.settings.mostrarmenu3 : 'undefined');

     const shortidurl = state.party.currentParty
     ? state.party.currentParty.short_id22
     : '';


function mostrarAlerta() {
  if (menuvalue === false) {
    searchState.setIsSearchUnlocked(false);
    //////console.log("menuvalue es falso");
  } else if (menuvalue === true) {
    //searchState.setIsSearchUnlocked(true);
    //////console.log("menuvalue es verdadero");
  }
}

mostrarAlerta()
//console.log('Estado de bloqueo de búsqueda:', searchState.isSearchUnlocked);
    return {
        searchError: state.partyView.searchError,
        searchInProgress: state.partyView.searchInProgress,
        tracks: sortedTrackSelector(state),
        isSearchUnlocked: searchState.isSearchUnlocked,
        menuvalue: menuvalue
    };
};

customElements.define('party-search', connect(mapStateToProps, {})(PartySearchView));
