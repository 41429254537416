import '@polymer/paper-fab/paper-fab';
import '@polymer/paper-icon-button/paper-icon-button';
import { connect, withFit } from 'fit-html';
import { html } from 'lit-html';
import { createSelector } from 'reselect';
import Toastify from 'toastify-js';
//import "toastify-js/src/toastify.css";
import { previewPlay, previewPause, previewStop } from '../actions/preview-player';

import { installPlaybackMaster } from '../actions/party-data';
import { togglePlaybackStart } from '../actions/playback-spotify';
import { removeTrackAction, requestSetVoteAction as setVoteAction } from '../actions/queue';
import srcsetImg from '../components/srcset-img';
import {
    hasOtherPlaybackMasterSelector,
    isPartyOwnerSelector,
    isPlaybackMasterSelector,
    playbackMasterSelector,
} from '../selectors/party';
import {
    artistJoinerFactory,
    currentTrackSelector,
    singleMetadataSelector,
    singleTrackSelector,
    tracksEqual,
    voteStringGeneratorFactory,
} from '../selectors/track';
import { hasConnectedSpotifyAccountSelector } from '../selectors/users';
import { Metadata, State, Track, TrackReference } from '../state';
import sharedStyles from '../util/shared-styles';

interface PartyTrackProps {
    artistName: string | null;
    enablePlayButton: boolean;
    hasConnectedSpotifyAccount: boolean;
    hasVoted: boolean;
    isOwner: boolean;
    isMusicPlaying: boolean;
    isPlayingTrack: boolean;
    metadata: Metadata | null;
    showRemoveButton: boolean;
    showTakeoverButton: boolean;
    togglingPlayback: boolean;
    track: Track | null;
    voteString: string;
    previewvalue: boolean|string;
    isPreviewPlaying: boolean;
    currentPreviewUrl: string | null;
    progress: number;
    duration: number;
    isDownloading: boolean;
    downloadProgress: number;
    downloadId: string | null;
    showDownloadButton: boolean;
}

interface PartyTrackDispatch {
    removeTrack: (ref: TrackReference) => void;
    setVote: (ref: TrackReference, isCurrentTrack: boolean) => void;
    takeOverPlayback: () => void;
    togglePlayPause: () => void;
    playPreview: (url: string) => void;
    pausePreview: () => void;
    stopPreview: () => void;
    //downloadTrack: (ref: TrackReference) => void;
    //cancelDownload: (downloadId: string) => void;
}

interface PartyTrackOwnProps {
    playing: boolean;
    trackid: string;
}

type PartyTrackRenderProps = PartyTrackProps & PartyTrackOwnProps & PartyTrackDispatch;

const gradients = [
    "linear-gradient(to right, #1ed760, #1db954)", // Verde Spotify
    "linear-gradient(to right, #00c6ff, #0072ff)", // Azul Eléctrico
    "linear-gradient(to right, #fc466b, #3f5efb)", // Rosa a Púrpura
    "linear-gradient(to right, #834d9b, #d04ed6)", // Púrpura Profundo
    "linear-gradient(to right, #4776E6, #8E54E9)", // Azul a Violeta
    "linear-gradient(to right, #FF8008, #FFC837)", // Naranja Cálido
    "linear-gradient(to right, #2193b0, #6dd5ed)"  // Azul Océano
];

/* tslint:disable:max-line-length */
const LikeButtonIcon = (props: PartyTrackRenderProps): string => {
    if (!props.track) {
        return '';
    }

    if (props.hasVoted) {
        return 'theparcheplayer:favorite';
    } else if (props.track.vote_count > 0 || props.track.is_fallback) {
        return 'theparcheplayer:favorite-border';
    } else {
        return 'theparcheplayer:add';
    }
};







const PlayButton = (props: PartyTrackRenderProps) => {
    if (props.isPlayingTrack) {
        return html`
            ${props.isOwner && props.track
                ? html`
                    <paper-icon-button icon="theparcheplayer:skip-next"
                                       @click=${() => props.removeTrack(props.track!.reference)}
                                       title="Skip ${props.metadata ? props.metadata.name : 'Cargando...'}">
                    </paper-icon-button>
                `
                : null}
            <div class="fab-spinner">
                <paper-spinner-lite .active=${props.togglingPlayback}></paper-spinner-lite>
                <paper-fab mini
                          icon=${props.isMusicPlaying ? 'theparcheplayer:pause' : 'theparcheplayer:play-arrow'}
                           @click=${props.togglePlayPause}
                           .disabled=${!props.enablePlayButton}>
                </paper-fab>
            </div>
        `;
    } else {
        return props.track
            ? html`
            <paper-icon-button icon="${LikeButtonIcon(props)}"
            @click=${() => {
                props.setVote(props.track!.reference, !props.hasVoted);
            }}
            title="${(props.hasVoted ? "Quitar votos " : "Vota por ") + (props.metadata ? props.metadata.name : 'Cargando...')}">
            </paper-icon-button>
            `
            : null;
    }
};



//////console.log("7 party")


const maxLength = 30; // Establece la longitud máxima de caracteres aquí


export const PartyTrack = (props: PartyTrackRenderProps) => {
    // console.log('=== INFORMACIÓN COMPLETA DE LA CANCIÓN ===');
    
    // // Información del Track
    // console.log('--- TRACK INFO ---');
    // console.log('Track completo:', props.track);
    // if (props.track) {
    //     console.log('Reference:', props.track.reference);
    //     console.log('Vote count:', props.track.vote_count);
    //     console.log('Is fallback:', props.track.is_fallback);
    //    // console.log('Added by:', props.track.added_by);
    //     console.log('Added at:', props.track.added_at);
    // }




    return html`
    ${sharedStyles}
    <style>
        :host {
            box-sizing: content-box;
            padding: 5px 16px;
            display: flex;
            align-items: center;
            flex-direction: row;
        }

        :host([playing]) {
            background-color: #22262b;
            padding: 13px 16px;
        }

        :host([playing]) + :host {
            padding-top: 13px;
        }

        :host([playing]) img {
            box-shadow: 0 1px 4px 0 rgba(0, 0, 0, 0.5);
        }

        :host([playing]) .metadata-wrapper {
            margin-right: 20px;
        }


        img, .empty {
            background: rgba(0, 0, 0, 0.2);
            flex-shrink: 0;
            height: 54px;
            margin-right: 15px;
            width: 54px;
             border-radius: 50%;
            overflow: hidden;
            transition: transform 0.3s ease;
        }
        
        /* Añadir animación de rotación para la imagen */
        @keyframes rotate-album {
            from { transform: rotate(0deg); }
            to { transform: rotate(360deg); }
        }
        
        .rotate-animation {
            animation: rotate-album 6s linear infinite;
        }

        .metadata-wrapper {
            margin-top: 2px;
            overflow: hidden;
            user-select: none; /* Evita que el texto sea seleccionable */
        }

        h2 {
            margin: 0;
            font-weight: lighter;
            font-size: 15px;
            line-height: 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        aside {
            margin: 2px 0;
            font-weight: 300;
            font-size: 13px;
            line-height: 20px;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
        }

        .dot {
            margin: 0 4px;
        }

        .icon-wrapper {
            display: flex;
            margin-left: auto;
            flex-basis: 40px;
        }

        paper-fab {
            color: white;
            background-color: var(--primary-color);
        }

        paper-fab[disabled] {
            opacity: 0.7;
        }

        paper-icon-button {
            margin-left: 5px;
            padding: 6px;
        }

        .fab-spinner {
            margin-left: 5px;
            position: relative;
        }

        .fab-spinner paper-spinner-lite {
            --paper-spinner-stroke-width: 2px;
            --offset: calc(-1 * var(--paper-spinner-stroke-width));
            --size: calc(40px + 2 * var(--paper-spinner-stroke-width));

            position: absolute;
            top: var(--offset);
            left: var(--offset);
            width: var(--size);
            height: var(--size);
            --paper-spinner-color: white;
            pointer-events: none;
        }
.cover-container {
    position: relative;
    display: inline-block;
    // border-radius: 50%;
    overflow: hidden;
}

.button-container {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 1;
   
}

.controls-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
   
}

.progress-bar-container {
    position: absolute;
    top: -6px;
    left: -10px;
    width: 100%;
    height: 100%;
    pointer-events: none;
    padding: 3px;
    box-sizing: border-box;
}

.progress-bar-circle {
    position: absolute;
    width: 100%;
    height: 100%;
    transform: rotate(-90deg);
    padding: 3px;
}

.progress-bar-circle circle {
    fill: none;
    stroke-linecap: round;
    transition: stroke-dashoffset 0.1s linear;
}

.progress-bar-circle .progress-track {
    stroke: rgba(255, 255, 255, 0.2);
    stroke-width: 6;
}

@keyframes gradient {
    0% {
        stroke: #ff6b6b;
    }
    25% {
        stroke: #4ecdc4;
    }
    50% {
        stroke: #45b7d1;
    }
    75% {
        stroke: #96c93d;
    }
    100% {
        stroke: #ff6b6b;
    }
}

.progress-bar-circle .progress-indicator {
    stroke-width: 6;
    opacity: 0.9;
    animation: gradient 3s linear infinite;
}

.play-pause-button {
    --paper-icon-button-ink-color: var(--primary-color);
    color: white;
    opacity: 1;
    padding: 8px;
    margin: 0;
    position: absolute;
    top: 46%;
    left: 39%;
    transform: translate(-50%, -50%);
}

    </style>


<div class="cover-container">
    ${props.metadata
        ? html`<img src="${props.metadata.cover[0].url}" 
                   class="${props.isPreviewPlaying && props.currentPreviewUrl === (props.metadata ? props.metadata.previewUrl : null) ? 'rotate-animation' : ''}" 
                   alt="${props.metadata.name}">`
        : html``}
    <div class="controls-overlay" style="${(!props.metadata || !props.metadata.previewUrl || !props.previewvalue) ? 'display: none;' : ''}">
        <div class="button-container">
            <paper-icon-button
                class="play-pause-button"
                icon=${props.isPreviewPlaying && props.currentPreviewUrl === (props.metadata ? props.metadata.previewUrl : null) ? 'theparcheplayer:pause' : 'theparcheplayer:play-arrow'}
                @click=${() => {
                    if (props.metadata && props.metadata.previewUrl) {
                        if (props.isPreviewPlaying && props.currentPreviewUrl === props.metadata.previewUrl) {
                            props.pausePreview();
                            if (currentToast) {
                                currentToast.hideToast();
                            }
                        } else {
                            if (props.currentPreviewUrl && props.currentPreviewUrl !== props.metadata.previewUrl) {
                                props.stopPreview();
                            }
                            props.playPreview(props.metadata.previewUrl);
                            if (currentToast) {
                                currentToast.hideToast();
                            }
                        }
                    }
                }}
            ></paper-icon-button>
        </div>
        ${props.isPreviewPlaying && props.currentPreviewUrl === (props.metadata ? props.metadata.previewUrl : null) ? html`
            <div class="progress-bar-container">
                <svg class="progress-bar-circle" viewBox="0 0 60 60">
                    <defs>
                        <linearGradient id="progressGradient" x1="0%" y1="0%" x2="100%" y2="0%">
                            <stop offset="0%" style="stop-color:#ff6b6b" />
                            <stop offset="25%" style="stop-color:#4ecdc4" />
                            <stop offset="50%" style="stop-color:#45b7d1" />
                            <stop offset="75%" style="stop-color:#96c93d" />
                            <stop offset="100%" style="stop-color:#ff6b6b" />
                        </linearGradient>
                    </defs>
                    <circle 
                        class="progress-track"
                        cx="30" 
                        cy="30" 
                        r="26"
                    ></circle>
                    <circle 
                        class="progress-indicator"
                        cx="30" 
                        cy="30" 
                        r="26"
                        style="stroke-dasharray: 163.36; stroke-dashoffset: ${163.36 - (((props.progress || 0) / (props.duration || 1)) * 163.36)}">
                    </circle>
                </svg>
            </div>
        ` : html`
            <div class="progress-bar-container">
                <svg class="progress-bar-circle" viewBox="0 0 60 60">
                    <circle 
                        class="progress-track"
                        cx="30" 
                        cy="30" 
                        r="26"
                    ></circle>
                </svg>
            </div>
        `}
    </div>
</div>


    <div class="metadata-wrapper">




               <h2 style="${props.isPreviewPlaying && props.currentPreviewUrl === (props.metadata ? props.metadata.previewUrl : null) ? 'color: #1ed760;' : ''}">
            ${props.metadata
                ? props.metadata.name.length > maxLength
                    ? props.metadata.name.substring(0, maxLength) + " ..."
                    : props.metadata.name
                : 'Cargando...'}
        </h2>
        ${props.artistName
            ? html`
                <aside>
                    <span>${props.voteString}</span>
                     <span class="dot">&middot;</span>
                                  <a>
                    ${props.artistName.length > maxLength
                        ? props.artistName.substring(0, maxLength) + " ..."
                        : props.artistName}
                </a>
                   
                    

                </aside>
            `
            : null
        }
    </div>

    <div class="icon-wrapper">
            ${props.showTakeoverButton
            ? html`
                <paper-icon-button icon="theparcheplayer:download"
                                   @click=${props.takeOverPlayback}
                                   title="Transferir la reproducción al dispositivo actual">
                </paper-icon-button>
            `
            : null}
        ${props.showRemoveButton
            ? html`
                <paper-icon-button icon="theparcheplayer:clear"
                                   @click=${() => props.removeTrack(props.track!.reference)}
                                   title="Eliminar ${props.metadata ? props.metadata.name : 'Cargando...'} de la lista">
                </paper-icon-button>
            `
            : null}
                    ${props.track && props.track.reference
            ? html`
                <paper-icon-button icon="theparcheplayer:spotify"
                    @click=${() => {
                        const spotifyId = props.track && props.track.reference ? props.track.reference.id : '';
                        window.open(`https://open.spotify.com/track/${spotifyId}`, '_blank');
                        Toastify({
                            text: `🎵 Abriendo ${props.metadata ? props.metadata.name : 'canción'} en Spotify`,
                            duration: 3000,
                            gravity: "bottom",
                            position: "center",
                            style: {
                                background: gradients[Math.floor(Math.random() * gradients.length)],
                                borderRadius: "20px",
                            }
                        }).showToast();
                    }}
                    title="Abrir en Spotify ${props.metadata ? props.metadata.name : 'canción'}">
                </paper-icon-button>
            `
            : null}
      

        ${PlayButton(props)}
    </div>
    `;
};
/* tslint:enable */


const isCompatibleSelector = (s: State) => s.player.isCompatible;

/**
 * Computes whether the play fab is enabled.
 *
 * Enable it when
 * 1. we're Party Owner AND
 * 2. we're not toggling the playback right now AND
 * 3. we're either on a compatible device, or we're just controlling some other device AND
 * 4. we either have a Spotify account connected, or we're just controlling some other device.
 */
const enablePlayButtonSelector = createSelector(
    isPartyOwnerSelector,
    (s: State) => s.player.togglingPlayback,
    isCompatibleSelector,
    hasConnectedSpotifyAccountSelector,
    hasOtherPlaybackMasterSelector,
    (isOwner, isToggling, isCompatible, hasSptAccount, hasOtherMaster) =>
        isOwner &&
        !isToggling &&
        ((!isCompatible && hasOtherMaster) || isCompatible) &&
        ((!hasSptAccount && hasOtherMaster) || hasSptAccount)
);

export const createMapStateToPropsFactory = (
    trackSelector: (state: State, trackId: string) => Track | null,
) => {


    const hasVotesOrIsFallbackSelector = createSelector(trackSelector, track =>
        Boolean(track && (track.vote_count > 0 || track.is_fallback)),
    );

    /*
     * Since the selectors use component props, one for each instance must be created.
     */
    return () => {
        const artistJoiner = artistJoinerFactory();
        const isPlayingSelector = createSelector(
            currentTrackSelector,
            trackSelector,
            (currentTrack, track) => tracksEqual(currentTrack, track),
        );
        const showRemoveTrackButtonSelector = createSelector(
            isPartyOwnerSelector,
            hasVotesOrIsFallbackSelector,
            isPlayingSelector,
            (isOwner, hasVotesOrIsFallback, isPlaying) =>
                isOwner && hasVotesOrIsFallback && !isPlaying,
        );
        const showTakeoverButtonSelector = createSelector(
            isPartyOwnerSelector,
            isPlaybackMasterSelector,
            playbackMasterSelector,
            isPlayingSelector,
            isCompatibleSelector,
            hasConnectedSpotifyAccountSelector,
            (isOwner, isMaster, master, isPlaying, isCompatible, hasSptConnected) =>
                isPlaying && isOwner && !isMaster && !!master && isCompatible && hasSptConnected,
        );








        const voteStringGenerator = voteStringGeneratorFactory(trackSelector);

        return (state: State, ownProps: PartyTrackOwnProps): PartyTrackProps => {



//agrego esta constante para poder ver el valor de mostrarmenu (para ver el chat)
const previewvalue = state.party.currentParty && state.party.currentParty.settings ? state.party.currentParty.settings.mostrarmenu2 : 'undefined';

// Añadiendo la constante para controlar la visibilidad del botón de descarga
const showDownloadButton = state.party.currentParty && state.party.currentParty.settings ? state.party.currentParty.settings.mostrarmenu7 !== false : true;






    return {
        artistName: artistJoiner(state, ownProps.trackid),
        enablePlayButton: enablePlayButtonSelector(state),
        hasConnectedSpotifyAccount: hasConnectedSpotifyAccountSelector(state),
        hasVoted: !!state.party.userVotes && state.party.userVotes[ownProps.trackid] === true,
        isOwner: isPartyOwnerSelector(state),
        isMusicPlaying: !!state.party.currentParty && state.party.currentParty.playback.playing,
        isPlayingTrack: isPlayingSelector(state, ownProps.trackid),
        metadata: singleMetadataSelector(state, ownProps.trackid),
        showRemoveButton: showRemoveTrackButtonSelector(state, ownProps.trackid),
        showTakeoverButton: showTakeoverButtonSelector(state, ownProps.trackid),
        togglingPlayback: state.player.togglingPlayback,
        track: trackSelector(state, ownProps.trackid),
        voteString: voteStringGenerator(state, ownProps.trackid),
        previewvalue: previewvalue,
        isPreviewPlaying: state.previewPlayer.isPlaying,
        currentPreviewUrl: state.previewPlayer.currentPreviewUrl,
        progress: state.previewPlayer.progress,
        duration: state.previewPlayer.duration,
        isDownloading: false,
        downloadProgress: 0,
        downloadId: null,
        showDownloadButton: showDownloadButton,
    };
};
    };
};

export const mapDispatchToProps: PartyTrackDispatch = {
    removeTrack: (ref: TrackReference) => removeTrackAction(ref, false),
    setVote: (ref: TrackReference, isCurrentTrack: boolean) => setVoteAction(ref, isCurrentTrack),
    takeOverPlayback: installPlaybackMaster,
    togglePlayPause: togglePlaybackStart,
    playPreview: (url: string) => previewPlay(url),
    pausePreview: () => previewPause(),
    stopPreview: () => previewStop(),
    // downloadTrack: (ref: TrackReference) => {
    //     const trackId = ref.id;
    //     const trackName = 'Canción'; // Idealmente obtener el nombre real aquí
        
    // },
    // cancelDownload: (downloadId: string) => {
    //     cancelDownload(downloadId);
    // }
};

export const PartyTrackElementBase = withFit<PartyTrackOwnProps, PartyTrackRenderProps>(
    PartyTrack,
    {
        playing: Boolean,
        trackid: String,
    },
)(HTMLElement);

const PartyTrackElement = connect(
    createMapStateToPropsFactory(singleTrackSelector),
    mapDispatchToProps,
)(PartyTrackElementBase);

customElements.define('party-track', PartyTrackElement);

// Variable global para gestionar las notificaciones Toastify
// Usada para asegurar que solo se muestre una notificación a la vez
let currentToast: Toastify | null = null;







