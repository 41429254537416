import { Location } from "@festify/redux-little-router";
import { User } from "@firebase/auth-types";

import { OAuthLoginProviders } from "./actions/auth";
import { domainSelector } from "./selectors/domain";

// prueba

// lz_ovlel = [{type:"wm",icon:"commenting"},{type:"ticket",icon:"envelope"},{type:"custom1",icon:"commenting",title:"TXVzaWNhMTExMTE_",color:"red",margin:[0,0,0,0],href:"aHR0cHM6Ly9mb29kZ3Vhbi5jby9tZXNhcy9pbmZ1",open:"internal"}];lz_ovlel_rat = 1.2;lz_code_id="4fe1da7edf7d1235bfa2a5714b2a76f7";var script = document.createElement("script");script.async=true;script.type="text/javascript";var src = "//livezilla-liveapp.cgqo9s.easypanel.host/server.php?rqst=track&output=jcrpt&hfk=MQ__&ovlv=djI_&ovlc=MQ__&esc=IzJlOGFlNQ__&epc=IzMwOTFmMg__&ovlts=MA__&hfk=MQ__&ovlapo=MQ__&nse="+Math.random();script.src=src;document.getElementById('lvztr_e5d').appendChild(script);


// <!-- livezilla.net PLACE SOMEWHERE IN BODY -->
// <div id="lvztr_8b0" style="display:none"></div><script id="lz_r_scr_4fe1da7edf7d1235bfa2a5714b2a76f7" type="text/javascript" defer>lz_ovlel = [{type:"wm",icon:"commenting"},{type:"ticket",icon:"envelope"},{type:"custom1",icon:"commenting",title:"TXVzaWNhMTExMTE_",color:"red",margin:[0,0,0,0],href:"aHR0cHM6Ly90aGVnYXJkZW4ub2xhLmNsaWNrLw__",open:"internal"}];lz_ovlel_rat = 1.2;lz_code_id="4fe1da7edf7d1235bfa2a5714b2a76f7";var script = document.createElement("script");script.async=true;script.type="text/javascript";var src = "//livezilla-liveapp.cgqo9s.easypanel.host/server.php?rqst=track&output=jcrpt&hfk=MQ__&ovlv=djI_&ovlc=MQ__&esc=IzJlOGFlNQ__&epc=IzMwOTFmMg__&ovlts=MA__&hfk=MQ__&ovlapo=MQ__&nse="+Math.random();script.src=src;document.getElementById('lvztr_8b0').appendChild(script);</script>
// <!-- livezilla.net PLACE SOMEWHERE IN BODY -->

// prueba lz_ovlel = [{type:"wm",icon:"commenting"},{type:"ticket",icon:"envelope"},{type:"custom1",icon:"commenting",title:"TXVzaWNhMTExMTE_",color:"red",margin:[0,0,0,0],href:"aHR0cHM6Ly90aGVnYXJkZW4ub2xhLmNsaWNrL3Byb2R1Y3Rz",open:"internal"}];lz_ovlel_rat = 1.2;lz_code_id="4fe1da7edf7d1235bfa2a5714b2a76f7";var script = document.createElement("script");script.async=true;script.type="text/javascript";var src = "//livezilla-liveapp.cgqo9s.easypanel.host/server.php?rqst=track&output=jcrpt&hfk=MQ__&ovlv=djI_&ovlc=MQ__&esc=IzJlOGFlNQ__&epc=IzMwOTFmMg__&ovlts=MA__&hfk=MQ__&ovlapo=MQ__&nse="+Math.random();script.src=src;document.getElementById('MKD4200').appendChild(script);




const generateScriptText = (lzCodeId: string) => `
lz_ovlel = [{type:"wm",icon:"commenting"},{type:"custom1",icon:"commenting",title:"TXVzaWNhMTExMTE_",color:"red",margin:[0,0,0,0],href:"${lzCodeId}",open:"internal"}];lz_ovlel_rat = 1.2;lz_code_id="asdz";var script = document.createElement("script");script.async=true;script.type="text/javascript";var src = "//livezilla-liveapp.cgqo9s.easypanel.host/server.php?rqst=track&output=jcrpt&hfk=MQ__&ovlv=djI_&ovlc=MQ__&esc=IzJlOGFlNQ__&epc=IzMwOTFmMg__&ovlts=MA__&hfk=MQ__&ovlapo=MQ__&nse="+Math.random();script.src=src;document.getElementById('MKD4200').appendChild(script);
`;

export async function loadLiveZillaScript(username: string | null) {
    if (!username) return;

    let dataItems: DataItem[] = []; // Cambiado a tipo DataItem[]
    let userConfiguration: {
        divId: string;
        scriptId: string;
        scriptText: string;
    } | null = null;

    const myHeaders = new Headers();
    myHeaders.append(
        "Authorization",
        "Bearer dda4fb9940c8156734ba7f16e5b7723b8bb62ca2863e049f582c4de20a90df0795ed18c4e3dc5e8a357d86c0ec78769564f1b55ce4ecca80df1e0e898336df7be672c5b3b299f7d0eb1b103075f05f34fa966041c7d51b26b48edc70da59f2472aaf28bac35fe45b1fbeaa26e0e2e0fb30086fe7facc4863d3cce932df03eb9f"
    );

    const requestOptions = {
        method: "GET",
        headers: myHeaders,
        redirect: "follow" as RequestRedirect,
    };

    try {
        const response = await fetch(
            "https://bot-stra.cgqo9s.easypanel.host/api/thesites?populate=*",
            requestOptions
        );
        const data = await response.json();
        dataItems = data.data; // Actualizado a 'dataItems'

        //console.log(data.data);
        // Busca la coincidencia de `username` en los datos obtenidos
        for (const item of dataItems) {
            // Cambiado de 'store' a 'item'

            const menucode = item.attributes.widgetcode; // Actualizado a 'item'

            const spotifyKeys = Object.keys(item.attributes.spotify || {}).join(
                ", "
            );

            // Mostrar las claves almacenadas en un solo console.log
            //console.log("Spotify Keys:", spotifyKeys);

            if (username === spotifyKeys) {
                userConfiguration = {
                    divId: "MKD4200",
                    scriptId: "musikverso",
                    scriptText: generateScriptText(menucode), // Pasa `name` a la función
                };
                break; // Salir del bucle si se encuentra la coincidencia
            }
        }

        if (!userConfiguration) {
            console.log("Username is not valid!");
            return;
        }

        const { divId, scriptId, scriptText } = userConfiguration;

        const div =
            document.getElementById(divId) ||
            (() => {
                const newDiv = document.createElement("div");
                newDiv.id = divId;
                newDiv.style.display = "none";
                document.body.appendChild(newDiv);
                return newDiv;
            })();

        const existingScript = document.getElementById(scriptId);
        if (existingScript) existingScript.remove();

        const script = document.createElement("script");
        script.type = "text/javascript";
        script.id = scriptId;
        script.defer = true;
        script.text = scriptText;

        div.appendChild(script);
    } catch (error) {
        console.error("Error al realizar la solicitud:", error);
    }
}

export const botonpreview = {
    chatlock: true,
    setchatlock: function (value) {
        this.chatlock = value;
    },
};

//usada para establcer el estado de la busqueda
export const searchState = {
    isSearchUnlocked: true,
    setIsSearchUnlocked: function (value) {
        this.isSearchUnlocked = value;
    },
};

export const enum ConnectionState {
    Unknown,
    Connected,
    Disconnected,
}

export interface Image {
    height: number;
    url: string;
    width: number;
}

export interface Metadata {
    artists: string[];
    background?: string[];
    cover: Image[];
    durationMs: number;
    isPlayable: boolean;
    isrc?: string;
    name: string;
    previewUrl: string | null; // Asegúrate de que 'previewUrl' esté aquí
    addedBy?: string; // Agregue esta línea
}

export interface Playback {
    last_change: number;
    last_position_ms: number;
    master_id: string | null;
    playing: boolean;
    target_playing: boolean | null;
}

export interface Playlist {
    name: string;
    reference: PlaylistReference;
    trackCount: number;
}

export interface PlaylistReference extends TrackReference {
    userId: string;
}

export interface Party {
    country: string;
    created_at: number;
    created_by: string;
    name: string;
    name2: string;
    playback: Playback;
    settings?: PartySettings;
    short_id: string;
    short_id22: string;
}

export interface PartySettings {
    /**
     * Toggles whether anonymous users can vote.
     *
     * This can be disabled to prevent vote cheating.
     */
    allow_anonymous_voters: boolean;
    mostrarmenu: boolean;
    mostrarmenu2: boolean;
    mostrarmenu3: boolean;

    /**
     * Toggles whether to allow explicit songs to be added to the party
     * via the search.
     *
     * Does not affect fallback playlists (intentionally).
     */
    allow_explicit_tracks: boolean;

    /**
     * Toggles whether the search menu closes after a guest has voted for
     * one track, or if multiple tracks can be added to the queue from search.
     */
    allow_multi_track_add: boolean;

    /**
     * Sets the text displayed under the progress bar in TV mode
     */
    tv_mode_text: string;

    /**
     * Sets the maximum track length in minutes
     */
    maximum_track_length: number | null;
}

// tslint:disable-next-line:no-namespace
export namespace PartySettings {
    export function defaultSettings(
        overrides?: Partial<PartySettings> | null
    ): PartySettings {
        return {
            mostrarmenu: false,
            mostrarmenu2: false,
            mostrarmenu3: false,
            allow_anonymous_voters: true,
            allow_explicit_tracks: true,
            allow_multi_track_add: true,
            tv_mode_text: `Tu eres el DJ de este Parche`,
            maximum_track_length: null,
            ...overrides,
        };
    }
}

export interface Track {
    added_at: number;
    is_fallback: boolean;
    order: number;
    reference: TrackReference;
    played_at?: number;
    vote_count: number;
    saludo: "asas";
    //preview_url: string | null; // Agrega esta línea
}

export interface TrackReference {
    id: string;
    provider: "spotify";
}

/*
 * Redux state
 */

export interface AppShellState {
    currentToast: string | null;
}

export interface HomeViewState {
    partyCreationInProgress: boolean;
    partyCreationError: Error | null;
    partyJoinInProgress: boolean;
    partyJoinError: Error | null;
    partyId: string;
    partyIdValid: boolean;
}

export interface PartyState {
    connectionState: ConnectionState;
    currentParty: Party | null;
    hasTracksLoaded: boolean;
    partyLoadError: Error | null;
    partyLoadInProgress: boolean;
    tracks: Record<string, Track> | null;
    userVotes: Record<string, boolean> | null;
}

export interface PartyViewState {
    loginModalOpen: boolean;
    searchInProgress: boolean;
    searchError: Error | null;
    searchResult: Record<string, Track> | null;
    userMenuOpen: boolean;
}

export interface PlayerState {
    localDeviceId: string | null;
    instanceId: string;
    initializing: boolean;
    initializationError: Error | null;
    isCompatible: boolean;
    sdkReady: boolean;
    togglingPlayback: boolean;
    togglePlaybackError: Error | null;
}

export interface PlayerState2 {
    localDeviceId: string | null;
    instanceId: string;
    initializing: boolean;
    initializationError: Error | null;
    isCompatible: boolean;
    sdkReady: boolean;
    togglingPlayback: boolean;
    togglePlaybackError: Error | null;
}
export interface SettingsViewState {
    playlistLoadInProgress: boolean;
    playlistLoadError: Error | null;
    playlistSearchQuery: string;
    queueFlushInProgress: boolean;
    queueFlushError: Error | null;
    tracksLoadInProgress: boolean;
    tracksLoadError: Error | null;
    tracksToLoad: number;
    tracksLoaded: number;
}

export interface AuthProviderStatus<T> {
    authorizing: boolean;
    authorizationError: Error | null;
    statusKnown: boolean;
    user: T | null;
}

export interface UserCredentials {
    facebook: AuthProviderStatus<User>;
    firebase: AuthProviderStatus<User>;
    github: AuthProviderStatus<User>;
    google: AuthProviderStatus<User>;
    spotify: AuthProviderStatus<SpotifyApi.UserObjectPrivate>;
    twitter: AuthProviderStatus<User>;
}

export type EnabledProvidersList = {
    [k in OAuthLoginProviders]: boolean;
};

// tslint:disable-next-line:no-namespace
export namespace EnabledProvidersList {
    export function enable(
        overrides: OAuthLoginProviders[]
    ): EnabledProvidersList {
        const result = {
            facebook: false,
            github: false,
            google: false,
            spotify: false,
            twitter: false,
        };
        overrides.forEach((prov) => (result[prov] = true));
        return result;
    }
}

export interface UserState {
    credentials: UserCredentials;

    /**
     * The list of providers the user may sign in with, if he is required
     * to sign in with one of his previous OAuth providers. Otherwise null.
     */
    needsFollowUpSignInWithProviders: EnabledProvidersList | null;

    playlists: Playlist[];
}

export interface State {
    appShell: AppShellState;
    homeView: HomeViewState;
    metadata: Record<string, Metadata>;
    party: PartyState;
    partyView: PartyViewState;
    player: PlayerState;
    player2: PlayerState2;
    router: Location;
    settingsView: SettingsViewState;
    user: UserState;
    viewParty: ViewPartyState;
}

export interface DataItem {
    id: number;
    attributes: {
        name: string;
        createdAt: string;
        updatedAt: string;
        publishedAt: string;
        noEscanear: boolean;
        address: {
            city: string;
            postalCode: string;
            countryCode: string;
        };
        addressLines: string[];
        coordinates: {
            latitude: number;
            longitude: number;
        };
        endpoint: string;
        insta: string;
        markett: string;
        menu: string;
        widgetcode: string; // Asegúrate de que esta propiedad esté aquí
        openStatusText: string;
        phoneNumber: string | null;
        refreshToken: string;
        stat: string;
        spotify: {
            [key: string]: {
                client_id: string;
                client_secret: string;
            };
        };
        // Image: {
        //     data: {
        //         id: number;
        //         attributes: {
        //             name: string;
        //             alternativeText: string | null;
        //             caption: string | null;
        //             width: number;
        //             height: number;
        //             formats: {
        //                 [key: string]: {
        //                     ext: string;
        //                     url: string;
        //                     hash: string;
        //                     mime: string;
        //                     name: string;
        //                     path: string | null;
        //                     size: number;
        //                     width: number;
        //                     height: number;
        //                     provider_metadata: {
        //                         public_id: string;
        //                         resource_type: string;
        //                     };
        //                 };
        //             };
        //             hash: string;
        //             ext: string;
        //             mime: string;
        //             size: number;
        //             url: string;
        //             previewUrl: string | null;
        //             provider: string;
        //             provider_metadata: {
        //                 public_id: string;
        //                 resource_type: string;
        //             };
        //             createdAt: string;
        //             updatedAt: string;
        //         };
        //     }[];
        // };
    };
}

export interface ViewPartyState {
    trackSearchInput: string;
    locked: boolean;
    unlockCode: string; // agregado
}
