export const PREVIEW_PLAY = 'PREVIEW_PLAY';
export const PREVIEW_PAUSE = 'PREVIEW_PAUSE';
export const PREVIEW_STOP = 'PREVIEW_STOP';
export const PREVIEW_UPDATE_PROGRESS = 'PREVIEW_UPDATE_PROGRESS';

export type Actions =
    | ReturnType<typeof previewPlay>
    | ReturnType<typeof previewPause>
    | ReturnType<typeof previewStop>
    | ReturnType<typeof previewUpdateProgress>;

export const previewPlay = (previewUrl: string) => ({
    type: PREVIEW_PLAY as typeof PREVIEW_PLAY,
    payload: previewUrl,
});

export const previewPause = () => ({
    type: PREVIEW_PAUSE as typeof PREVIEW_PAUSE,
});

export const previewStop = () => ({
    type: PREVIEW_STOP as typeof PREVIEW_STOP,
});

export const previewUpdateProgress = (progress: number, duration: number) => ({
    type: PREVIEW_UPDATE_PROGRESS as typeof PREVIEW_UPDATE_PROGRESS,
    payload: { progress, duration },
});